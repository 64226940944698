// Migrated
<template>
  <div class="viewport-background-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.viewport-background-container {
  clip-path: inset(0 -100vw);
  max-width: 100%;

  &:before {
    content: '';
    height: 100vh;
    width: 200vw;
    position: absolute;
    left: -100vw;
    z-index: -1;
    background: theme('colors.white-blue')
  }
}
</style>
